import Swiper from 'swiper';
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Configura los módulos globalmente
Swiper.use([Autoplay, Mousewheel, Navigation, Pagination]);

export function initializeRotator(selector, options) {
   return new Swiper(selector, options);
}

export const reviewsOptions = {
   slidesPerView: 1,
   loop: true,
   spaceBetween: 30,
   autoplay: {
      delay: 4000,
      disableOnInteraction: true,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
   breakpoints: {
      768: {
         slidesPerView: 2,
         slidesPerGroup: 2,
         spaceBetween: 60,
      },
      1024: {
         slidesPerView: 3,
         slidesPerGroup: 1,
         spaceBetween: 30,
      },
      1280: {
         slidesPerView: 4,
         spaceBetween: 15,
      },
   },
};
